let navItems = [
  [
    {
      path: '/',
      icon: 'mdi-monitor-dashboard',
      color: 'accent'
    },
    {
      path: '/Company',
      icon: 'mdi-home-city-outline',
      color: 'accent'
    },
    {
      path: '/Web',
      icon: 'mdi-laptop',
      color: 'accent'
    }
  ],
  [
    {
      path: '/MessageCenter',
      icon: 'mdi-email-open-outline',
      color: 'yellow'
    },
    {
      path: '/Depot',
      icon: 'mdi-file-download-outline',
      color: 'yellow'
    }
  ],
  [
    {
      path: '/Buildings',
      icon: 'mdi-office-building',
      color: 'primary'
    },
    {
      path: '/Stuffs',
      icon: 'mdi-car-estate',
      color: 'primary'
    },
    {
      path: '/Tools',
      icon: 'mdi-pipe-wrench',
      color: 'primary'
    },
    {
      path: '/Suppliers',
      icon: 'mdi-human-dolly',
      color: 'primary'
    },
    {
      path: '/Offers',
      icon: 'mdi-tag-outline',
      color: 'primary'
    },
    {
      path: '/Contracts',
      icon: 'mdi-script-text-outline',
      color: 'primary'
    },
    {
      path: '/Staff',
      icon: 'mdi-badge-account-outline',
      color: 'primary'
    },
    {
      path: '/Trainings',
      icon: 'mdi-school-outline',
      color: 'primary'
    },
    {
      path: '/Communication',
      icon: 'mdi-bullhorn-outline',
      color: 'primary'
    },
    {
      path: '/Bank',
      icon: 'mdi-bank-outline',
      color: 'primary'
    },
  ],
  [
    {
      path: '/Invoices',
      icon: 'mdi-cash',
      color: 'warning'
    },
    {
      path: '/Finance',
      icon: 'mdi-chart-bar-stacked',
      color: 'warning'
    },
    {
      path: '/Data',
      icon: 'mdi-magnify',
      color: 'warning'
    },
  ],
  [
    {
      path: '/Teams',
      icon: 'mdi-account-group-outline',
      color: 'deep-orange'
    },
  ],
  [
    {
      path: '/Parameters',
      icon: 'mdi-tools',
      color: 'success'
    },
    {
      path: '/Assets',
      icon: 'mdi-package-variant-closed',
      color: 'success'
    },
    {
      path: '/MarketConfig',
      icon: 'mdi-chart-bell-curve',
      color: 'success'
    },
  ],
  [
    {
      path: '/ResultBonus',
      icon: 'mdi-hand-coin',
      color: 'success'
    },
    {
      path: '/ScoreEditor',
      icon: 'mdi-tag-edit-outline',
      color: 'success'
    },
    {
      path: '/Results',
      icon: 'mdi-finance',
      color: 'success'
    },
  ],
  [
    {
      path: '/Templates',
      icon: 'mdi-content-save-outline',
      color: 'info'
    },
    {
      path: '/ChangeWorld',
      icon: 'mdi-earth-arrow-right',
      color: 'info'
    },
  ],
  [
    {
      path: '/Dev',
      icon: 'mdi-xml',
      color: 'error'
    },
  ],
]

export default navItems